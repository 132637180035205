import * as React from 'react';

const NotFoundPage = () => {
  return (
    <main>
      <h1>404</h1>
      <p>Page not found.</p>
    </main>
  );
};

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
